import React, { useEffect } from "react";
import Layout from "../_components/Layout/Layout";
import UserForm from "../_components/UserForm/UserForm";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import StyledLink from "../_components/StyledLink/StyledLink";
import { goto } from "../_utils/Misc";
import Qr from "../_utils/Qr";

const Registration = () => {
    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            goto("/");
        }
    }, []);

    return (
        <Layout title="Registration">
            <MainTitle>PLEASE COMPLETE TO CLAIM YOUR GIFT</MainTitle>

            <PageSummary>
                Share your preferences with us to help us deliver the best experience to you.
            </PageSummary>

            <UserForm showEmail={true} btnText="CLAIM MY GIFT"/>

            <hr />

            <PageSummary>
                <small className="text-muted">
                    Fabacus processes your personal data on behalf of Mubadala Silicon Valley 
                    Classic. To learn more about the processing of your personal data and to 
                    exercise your rights please refer to <a href="/privacy/" style={{ "textDecoration": "underline" }} target="_blank">Privacy
                    Policy</a>.
                </small>
            </PageSummary>

            <hr />

            {/* <StyledLink to="/login">Already registered? Click here</StyledLink> */}
        </Layout>
    );
};

export default Registration;
